import React, { useState, useEffect } from 'react';
import { Select, Table, Divider, Typography, Button } from 'antd';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

const { Title } = Typography;
const { Option } = Select;

const YearReport = () => {
  const [year, setYear] = useState(null);
  const [reportData, setReportData] = useState([]);

  const fetchYearReport = async (selectedYear) => {
    try {
      const response = await axios.get(`https://api-back.greatmanagers.org/yearreport/${selectedYear}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        }
      });
      setReportData(response.data);
    } catch (error) {
      console.error('Error fetching year report:', error);
    }
  };

  useEffect(() => {
    if (year) {
      fetchYearReport(year);
    }
  }, [year]);

  const handleYearChange = (value) => {
    setYear(value);
  };

  const columns = [
    {
      title: 'Company ID',
      dataIndex: 'CompanyID',
      key: 'CompanyID',
    },
    {
      title: 'Company Name',
      dataIndex: 'CompanyName',
      key: 'CompanyName',
    },
    {
      title: 'Number of Nominated Managers',
      dataIndex: 'NumberOfNominatedManagers',
      key: 'NumberOfNominatedManagers',
    },
    {
      title: 'Total Survey Invites',
      dataIndex: 'TotalSurveyInvites',
      key: 'TotalSurveyInvites',
    },
    {
      title: 'Completed Count',
      dataIndex: 'CompletedCount',
      key: 'CompletedCount',
    },
    {
      title: 'Participation Percentage',
      dataIndex: 'ParticipationPercentage',
      key: 'ParticipationPercentage',
      render: (text) => `${text}%`,
    },
    {
      title: 'Number of Finalized Evaluatees',
      dataIndex: 'NumberOfFinalizedEvaluatees',
      key: 'NumberOfFinalizedEvaluatees',
    },
  ];

  const exportToCSV = () => {
    const csv = Papa.unparse(reportData, {
      header: true,
      delimiter: ",",
      quotes: false,
      encoding: "utf-8"
    });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `year_report_${year}.csv`);
  };

  return (
    <div style={{ backgroundColor: '#F6F7FB', padding: '20px' }}>
      <Title>Yearly Summary</Title>
      <Title level={5} style={{ marginTop: '-20px', marginBottom: '30px' }}>
      Analyze Yearly Data And Insights
            </Title>

      <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingBottom: '30px'
            }} >
      <Title level={5}>Select a Year</Title>
      <Select
        placeholder="Select year"
        onChange={handleYearChange}
        style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
      >
        <Option value="2023">2023</Option>
        <Option value="2024">2024</Option>
        <Option value="2025">2025</Option>
        {/* Add more options as needed */}
      </Select>
      </div>
      {reportData.length > 0 && (
        <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingBottom: '30px'
            }}>
          <Button type="primary" onClick={exportToCSV} style={{ marginBottom: 20, float: 'right', marginTop: '20px',backgroundColor:'#3B7DDE' }}>
            Export as CSV
          </Button>
          <Table
            dataSource={reportData}
            columns={columns}
            pagination={false}
            components={{
            header: {
              cell: (props) => (
                <th {...props} style={{ backgroundColor: '#F6F7FB' }} />
              ),
            },
          }}
          />
        </div>
      )}
    </div>
  );
};

export default YearReport;
