import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, Table, Spin, Button, Typography, Divider } from 'antd';
import { saveAs } from 'file-saver';

const { Option } = Select;
const { Title } = Typography;
const { Text } = Typography;

const SelfResponses = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompanyName, setSelectedCompanyName] = useState('');
    const [selectedCompanyYear, setSelectedCompanyYear] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [evaluatees, setEvaluatees] = useState([]);
    const [selectedEvaluateeID, setSelectedEvaluateeID] = useState('');
    const [evaResponses, setEvaResponses] = useState([]);
    const [isLoadingEvaResponses, setIsLoadingEvaResponses] = useState(false);

    useEffect(() => {
        fetchCompanies();
    }, []);

    useEffect(() => {
        if (selectedEvaluateeID) {
            fetchEvaluateeResponses(selectedEvaluateeID);
        }
    }, [selectedEvaluateeID]);

    const fetchCompanies = async () => {
        try {
            const response = await axios.get('https://api-back.greatmanagers.org/companiesi', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const fetchEvaluatees = async (companyId) => {
        try {
            const response = await axios.get(`https://api-back.greatmanagers.org/evaluatees/${companyId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setEvaluatees(response.data);
        } catch (error) {
            console.error('Error fetching evaluatees:', error);
        }
    };

    const fetchEvaluateeResponses = async (evaluateeID) => {
        setIsLoadingEvaResponses(true);
        try {
            const response = await axios.get(`https://api-back.greatmanagers.org/evaluateeresponses/${evaluateeID}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setEvaResponses(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            console.error('Error fetching responses:', error);
        } finally {
            setIsLoadingEvaResponses(false);
        }
    };

    const handleCompanyNameChange = (value) => {
        setSelectedCompanyName(value);
        setSelectedCompanyYear('');
        setCompanyId('');
        setSelectedEvaluateeID('');
        setEvaResponses([]);
        setEvaluatees([]);
    };

    const handleCompanyYearChange = (value) => {
        setSelectedCompanyYear(value);
        const selectedCompany = companies.find(company => 
            company.CompanyName === selectedCompanyName && company.CompanyYear === parseInt(value)
        );
        if (selectedCompany) {
            setCompanyId(selectedCompany.CompanyID);
            fetchEvaluatees(selectedCompany.CompanyID);
        } else {
            setCompanyId('Not found');
        }
    };

    const handleEvaluateeSelection = (value) => {
        setSelectedEvaluateeID(value);
    };

    const handleDownload = () => {
        const csvContent = convertTableToCSV(evaResponses);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'SelfResponses_data.csv');
    };

    const convertTableToCSV = (tableData) => {
        if (!tableData.length) return '';
        const rows = [];
        const headers = Object.keys(tableData[0]);
        rows.push(headers.join(','));
        tableData.forEach(row => {
            const values = headers.map(header => row[header]);
            rows.push(values.join(','));
        });
        return rows.join('\n');
    };

    const companyNames = [...new Set(companies.map(company => company.CompanyName))];
    const companyYears = selectedCompanyName ? 
        [...new Set(companies.filter(company => company.CompanyName === selectedCompanyName).map(company => company.CompanyYear))] : [];

    const evaluateeOptions = evaluatees.map(evaluatee => (
        <Option key={evaluatee.EvaluateeID} value={evaluatee.EvaluateeID}>
            {evaluatee.FirstName} {evaluatee.LastName}
        </Option>
    ));

    const uniqueEvaluators = Array.isArray(evaResponses) ? evaResponses.reduce((acc, curr) => {
        Object.keys(curr).forEach(key => {
            if (key !== 'QuestionText' && !acc.includes(key)) {
                acc.push(key);
            }
        });
        return acc;
    }, []) : [];

    const columns = [
        { title: 'Question', dataIndex: 'QuestionText', key: 'QuestionText' },
        ...uniqueEvaluators.map(evaluator => ({
            title: evaluator,
            dataIndex: evaluator,
            key: evaluator
        }))
    ];

    return (
        <div style={{ padding: '20px' }}>
            <Divider><Text strong>Detailed Reports</Text></Divider>

            <Title level={3}>Select a Company</Title>
            <Select
                style={{ width: '100%', marginBottom: '10px' }}
                placeholder="Select a company name"
                onChange={handleCompanyNameChange}
                value={selectedCompanyName}
            >
                {companyNames.map(name => (
                    <Option key={name} value={name}>{name}</Option>
                ))}
            </Select>

            <Select
                style={{ width: '100%', marginBottom: '20px' }}
                placeholder="Select a company year"
                onChange={handleCompanyYearChange}
                value={selectedCompanyYear}
                disabled={!selectedCompanyName}
            >
                {companyYears.map(year => (
                    <Option key={year} value={year}>{year}</Option>
                ))}
            </Select>

            <div className="dashboard-section">
                <Title level={3}>Self Responses</Title>
                <Title level={4}>Select an Evaluatee</Title>
                <Select
                    style={{ width: '100%' }}
                    placeholder="Select an evaluatee"
                    onChange={handleEvaluateeSelection}
                    value={selectedEvaluateeID}
                    disabled={!companyId}
                >
                    {evaluateeOptions}
                </Select>
                {selectedEvaluateeID && (
                    <div style={{ marginTop: '20px' }}>
                        {isLoadingEvaResponses ? (
                            <Spin />
                        ) : (
                            <>
                                <Table
                                    columns={columns}
                                    dataSource={evaResponses}
                                    rowKey={(record, index) => index}
                                    pagination={{ pageSize: 5 }}
                                />
                                <Button
                                    type="primary"
                                    style={{ marginTop: '10px' }}
                                    onClick={handleDownload}
                                >
                                    Download CSV
                                </Button>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelfResponses;
