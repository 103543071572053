import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Typography, message, Tag, DatePicker, Row, Col, Select } from 'antd';
import { CloseOutlined,SendOutlined } from '@ant-design/icons';
import axios from 'axios';
import emailTemplate from './emailtext';
import emailTemplate2 from './emailtext2'; // Assuming emailtext.js exports the email template string

const { Title } = Typography;
const { Option } = Select;

const EmailSender = ({ evaluators: initialEvaluators = [], companyName, numberOfQuestionsSelected, fullName, URL }) => {
    const [form] = Form.useForm();
    const [sending, setSending] = useState(false);
    const [emailBody, setEmailBody] = useState('');
    const [evaluators, setEvaluators] = useState(initialEvaluators); // Use local state for evaluators
    const [selectedTemplate, setSelectedTemplate] = useState(''); 
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    // Initialize emailBody with processed template
    useEffect(() => {
        const processedBody = emailTemplate.replace('[URL]', URL);
        setEmailBody(processedBody);
        form.setFieldsValue({ body: processedBody });
    }, [URL]);

    useEffect(() => {
        form.setFieldsValue({ to: evaluators.map(e => e.email).join(', ') });
    }, [evaluators]);

    useEffect(() => {
        if (selectedTemplate === 'template1') {
            setEmailBody(emailTemplate.replace('[URL]', URL));
        } else if (selectedTemplate === 'template2') {
            setEmailBody(emailTemplate2.replace('[URL]', URL));
        }
        // Update form value when template changes
        form.setFieldsValue({ body: emailBody });
    }, [selectedTemplate, URL]);

    const handleSubmit = async (values) => {
        setSending(true);
        try {
            console.log('Company Name:', companyName);
            console.log('Number of Questions Selected:', numberOfQuestionsSelected);
            console.log('Full Name:', fullName);
            console.log('FromDate:', values.fromDate);
            console.log('ToDate:', values.toDate);
            console.log('URL:', URL);
            console.log('Evaluators:', evaluators);

            await axios.post('https://api-back.greatmanagers.org/send-emails', {
                subject: values.subject,
                body: emailBody,
                evaluators: evaluators,
                companyName,
                numberOfQuestionsSelected,
                fullName,
                fromDate: fromDate,
                toDate: toDate
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });

            message.success('Email sent successfully!');
            form.resetFields();
        } catch (error) {
            console.error('Error sending email:', error);
            message.error('Failed to send email');
        } finally {
            setSending(false);
        }
    };

    const handleRemoveEmail = (emailToRemove) => {
        const updatedEvaluators = evaluators.filter(evaluator => evaluator.email !== emailToRemove);
        setEvaluators(updatedEvaluators); // Update state with the new list of evaluators
    };

    return (
        <div >
            <Title level={5} style={
                {
                    paddingBottom: '10px',
                }
            }>Email Sender</Title>
            <Form.Item
                    style={{ marginBottom: 0 }}
                >
                    <Row gutter={8}>
                        <Col span={12}>
                        <Form.Item
                            label="Dates From"
                            name="fromDate"
                            rules={[{ required: true, message: 'Please select the From date' }]}
                            labelCol={{ span: 24 }}  // Ensures the label takes the full width
                            wrapperCol={{ span: 24 }} // Ensures the date picker takes the full width
>
                                <DatePicker format="YYYY-MM-DD" 
                                    style={{ width: '100%' }}
                                    onChange={(date, dateString) => setFromDate(dateString)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                            label="Dates To"
                            name="ToDate"
                            rules={[{ required: true, message: 'Please select the From date' }]}
                            labelCol={{ span: 24 }}  // Ensures the label takes the full width
                            wrapperCol={{ span: 24 }} 
                            >
                                <DatePicker format="YYYY-MM-DD"
                                style={{ width: '100%' }}
                                onChange={(date, dateString) => setToDate(dateString)}
                                
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
            <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Form.Item
                    label="To"
                    name="to"
                    rules={[{ message: 'Please enter recipient email' }]}
                >
                    {evaluators.map((evaluator) => (
                        <Tag
                            key={evaluator.email}
                            style={{ borderRadius: '25px', margin: '5px', display: 'inline-flex', alignItems: 'center' }}
                        >
                            {evaluator.email}
                            <Button
                                type="text"
                                size="small"
                                icon={<CloseOutlined />}
                                onClick={() => handleRemoveEmail(evaluator.email)}
                                style={{ marginLeft: '5px', padding: 0 }}
                            />
                        </Tag>
                    ))}
                </Form.Item>
                <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: 'Please enter email subject' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Body"
                    name="body"
                    rules={[{ required: true, message: 'Please select an email template' }]}
                >
                    <Select onChange={setSelectedTemplate} placeholder="Select an email template">
                        <Option value="template1">EVP</Option>
                        <Option value="template2">Agile Leadership Awards</Option>
                    </Select>
                </Form.Item>
                
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={sending} icon={<SendOutlined /> } style = {{backgroundColor:'#3b7dde'}}>
                        Send Email
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default EmailSender;
