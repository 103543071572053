import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, Table, Typography, Button, Divider,Row,Col } from 'antd';
import { saveAs } from 'file-saver';

const { Option } = Select;
const { Title } = Typography;

function ReportCompletion() {
    const [companies, setCompanies] = useState([]);
    const [selectedName, setSelectedName] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [reportData, setReportData] = useState([]);

    useEffect(() => {
        axios.get('https://api-back.greatmanagers.org/companiesi', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                // Add any other headers here if needed
            }
        })
            .then(response => {
                setCompanies(response.data);
            })
            .catch(error => {
                console.error('Error fetching companies:', error);
            });
    }, []);

    const handleNameChange = (value) => {
        setSelectedName(value);
        setSelectedYear('');
        setCompanyId('');
        setReportData([]);
    };

    const handleYearChange = (value) => {
        setSelectedYear(value);

        const selectedCompany = companies.find(company =>
            company.CompanyName === selectedName && company.CompanyYear === parseInt(value)
        );
        const id = selectedCompany ? selectedCompany.CompanyID : 'Not found';
        setCompanyId(id);

        if (id !== 'Not found') {
            axios.get(`https://api-back.greatmanagers.org/reportcompletion/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            })
                .then(response => {
                    setReportData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching report completion data:', error);
                });
        } else {
            setReportData([]);
        }
    };

    const companyNames = [...new Set(companies.map(company => company.CompanyName))];
    const companyYears = selectedName ?
        [...new Set(companies.filter(company => company.CompanyName === selectedName).map(company => company.CompanyYear))] : [];

    const rowspans = calculateRowspans(reportData);

    const columns = [
        {
            title: 'Name of Nominee',
            dataIndex: 'NameOfNominee',
            render: (text, row, index) => {
                const name = row.NameOfNominee;
                if (rowspans[name] && index === reportData.findIndex(item => item.NameOfNominee === name)) {
                    return {
                        children: <span>{text}</span>,
                        props: { rowSpan: rowspans[name] },
                    };
                }
                return {
                    children: <span>{text}</span>,
                    props: { rowSpan: 0 },
                };
            },
        },
        {
            title: 'Evaluator Name',
            dataIndex: 'EvaluatorName',
        },
        {
            title: 'Evaluator Email',
            dataIndex: 'EvaluatorEmail',
        },
        {
            title: 'Completion',
            dataIndex: 'Completion',
        },
    ];

    const exportToCSV = () => {
        if (reportData.length === 0) {
            return;
        }

        const header = columns.map(column => column.title);
        const rows = reportData.map(row => columns.map(column => row[column.dataIndex]));

        let csvContent = header.join(",") + "\n";
        csvContent += rows.map(row => row.join(",")).join("\n");

        const blob = new Blob(["\ufeff" + csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'report_completion.csv');
    };

    return (
        <div style={{ backgroundColor: '#F6F7FB', padding: '20px' }}>
            <Title strong>Survey Status Report</Title>
            <Title level={5} style={{ marginTop: '-20px', marginBottom: '30px' }}>
            Analyze Evaluation Completion Data
            </Title>
            <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingBottom: '30px'
            }} >
            <Row gutter={16}>
                                <Col span={12}>
                                <Title level={5}>Company Name:</Title>
                                </Col>
                                <Col span={12}>
                                <Title level={5}>Year:</Title>
                                </Col>
                                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                <Select
                    value={selectedName}
                    onChange={handleNameChange}
                    placeholder="Select a company name"
                    style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
                >
                    {companyNames.map(name => (
                        <Option key={name} value={name}>{name}</Option>
                    ))}
                </Select>
                    </Col>
                    <Col span={12}>
                <Select
                    value={selectedYear}
                    onChange={handleYearChange}
                    disabled={!selectedName}
                    placeholder="Select a company year"
                    style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
                >
                    {companyYears.map(year => (
                        <Option key={year} value={year}>{year}</Option>
                    ))}
                </Select>
                    </Col>
                </Row>
            </div>
            {reportData.length > 0 && (
                <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingBottom: '30px'
            }}>
                        <Button type="primary" onClick={exportToCSV} style={{ marginTop: '20px', float: 'right' ,marginBottom: '20px', backgroundColor:'#3B7DDE' }}>
                            Export as CSV
                        </Button>
                    <Table
                        style={{ marginTop: 20 }}
                        columns={columns}
                        dataSource={reportData}
                        rowKey={(record, index) => index}
                        pagination={false}
                        components={{
            header: {
              cell: (props) => (
                <th {...props} style={{ backgroundColor: '#F6F7FB' }} />
              ),
            },
          }}
                    />
                </div>
            )}
        </div>
    );
}

const calculateRowspans = (data) => {
    const rowspans = {};
    data.forEach((item, index) => {
        if (index === 0 || item.NameOfNominee !== data[index - 1].NameOfNominee) {
            const name = item.NameOfNominee;
            rowspans[name] = rowspans[name] ? rowspans[name] + 1 : 1;
            for (let i = index + 1; i < data.length; i++) {
                if (data[i].NameOfNominee === name) {
                    rowspans[name]++;
                } else {
                    break;
                }
            }
        }
    });
    return rowspans;
};

export default ReportCompletion;
