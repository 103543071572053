import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, Table, Typography, Spin, Divider, Button, Row, Col } from 'antd';
import Papa from 'papaparse';

const { Title } = Typography;
const { Option } = Select;

const EvaluationResponses = () => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyName, setSelectedCompanyName] = useState('');
  const [selectedCompanyYear, setSelectedCompanyYear] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [evaluatees, setEvaluatees] = useState([]);
  const [selectedEvaluateeID, setSelectedEvaluateeID] = useState('');
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await axios.get('https://api-back.greatmanagers.org/companiesi', {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            // Add any other headers here if needed
        }
    });
      setCompanies(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const fetchEvaluatees = async (companyId) => {
    try {
      const response = await axios.get(`https://api-back.greatmanagers.org/evaluatees/${companyId}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            // Add any other headers here if needed
        }
    });
      setEvaluatees(response.data);
    } catch (error) {
      console.error('Error fetching evaluatees:', error);
    }
  };

  const fetchEvaluationResponses = async (evaluateeId) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://api-back.greatmanagers.org/evaluateeresponses/${evaluateeId}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            // Add any other headers here if needed
        }
    });
      if (response.data.length > 0) {
        const keys = Object.keys(response.data[0]);
        const columns = keys.map(key => ({
          title: key,
          dataIndex: key,
          key,
        }));
        setColumns(columns);
        setData(response.data);
      }
    } catch (error) {
      console.error('Error fetching evaluation responses:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCompanyNameChange = (value) => {
    setSelectedCompanyName(value);
    setSelectedCompanyYear('');
    setCompanyId('');
    setSelectedEvaluateeID('');
    setData([]);
    setEvaluatees([]);
  };

  const handleCompanyYearChange = (value) => {
    setSelectedCompanyYear(value);
    const selectedCompany = companies.find(company =>
      company.CompanyName === selectedCompanyName && company.CompanyYear === parseInt(value)
    );
    if (selectedCompany) {
      setCompanyId(selectedCompany.CompanyID);
      fetchEvaluatees(selectedCompany.CompanyID);
    } else {
      setCompanyId('');
    }
  };

  const handleEvaluateeChange = (value) => {
    setSelectedEvaluateeID(value);
    fetchEvaluationResponses(value);
  };

  const exportToCSV = () => {
    const csv = Papa.unparse(data);
    const blob = new Blob(["\ufeff" + csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'evaluation_responses.csv');
    link.click();
  };

  const companyNames = [...new Set(companies.map(company => company.CompanyName))];
  const companyYears = selectedCompanyName ?
    [...new Set(companies.filter(company => company.CompanyName === selectedCompanyName).map(company => company.CompanyYear))] : [];

  const evaluateeOptions = evaluatees.map(evaluatee => (
    <Option key={evaluatee.EvaluateeID} value={evaluatee.EvaluateeID}>
      {evaluatee.FirstName} {evaluatee.LastName}
    </Option>
  ));

  return (
    <div style={{ backgroundColor: '#F6F7FB', padding: '20px' }}>
      <Title strong>Nominee Feedback</Title>
      <Title level={5} style={{ marginTop: '-20px', marginBottom: '30px' }}>
      Analyze Nominee Responses And Insights
            </Title>
      <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingBottom: '30px'
            }} >
            <Row gutter={16}>
                                <Col span={8}>
                                <Title level={5}>Company Name:</Title>
                                </Col>
                                <Col span={8}>
                                <Title level={5}>Year:</Title>
                                </Col>
                                <Col span={8}>
                                <Title level={5}>Nominee Name:</Title>
                                </Col>
                                </Row>
            <Row gutter={16}>
                <Col span={8}>
      <Select

        placeholder="Select a company name"
        onChange={handleCompanyNameChange}
        value={selectedCompanyName}
        style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
      >
        {companyNames.map(name => (
          <Option key={name} value={name}>{name}</Option>
        ))}
      </Select>
        </Col>
        <Col span={8}>
      <Select

        placeholder="Select a company year"
        onChange={handleCompanyYearChange}
        value={selectedCompanyYear}
        disabled={!selectedCompanyName}
        style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
      >
        {companyYears.map(year => (
          <Option key={year} value={year}>{year}</Option>
        ))}
      </Select>
        </Col>
        <Col span={8}>
      <Select
        placeholder="Select an evaluatee"
        onChange={handleEvaluateeChange}
        value={selectedEvaluateeID}
        disabled={!companyId}
        style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
      >
        {evaluateeOptions}
      </Select>
        </Col>
        </Row>
</div>
      <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingBottom: '30px'
            }}>
      { loading ? (
        <Spin />
      ) : (
        <>
        <Button onClick={exportToCSV} type="primary" style={{ marginTop: '20px', float: 'right'  ,marginBottom: '20px' ,backgroundColor:'#3B7DDE' }}>
            Export as CSV
          </Button>
          <Table
            dataSource={data}
            columns={columns}
            rowKey={(record, index) => index}
            components={{
            header: {
              cell: (props) => (
                <th {...props} style={{ backgroundColor: '#F6F7FB' }} />
              ),
            },
          }}
          />
        </>
      )}
      </div>
    </div>
  );
};

export default EvaluationResponses;
