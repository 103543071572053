import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Button, Space, Typography, Switch, Select,Row,Col } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined , FormOutlined} from '@ant-design/icons';

const { Title, Text } = Typography;
const { Option } = Select;

const Questions = () => {
  const [questions, setQuestions] = useState([]);
  const [editedQuestion, setEditedQuestion] = useState({ id: '', text: '', category: '', language: '' });
  const [newQuestionText, setNewQuestionText] = useState('');
  const [newQuestionCategory, setNewQuestionCategory] = useState('');
  const [newQuestionLanguage, setNewQuestionLanguage] = useState('');
  const [loading, setLoading] = useState(true);
  const [language, setLanguage] = useState('ENG');

  useEffect(() => {
    fetchQuestions();
  }, [language]);

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const url = language === 'ENG' ? 'https://api-back.greatmanagers.org/questionlist' : 'https://api-back.greatmanagers.org/questionlistsin';

      const response = await axios.get(url, {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
              // Add any other headers here if needed
          }
      });

      
      setQuestions(response.data);
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (record) => {
    setEditedQuestion(record);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await axios.put(`https://api-back.greatmanagers.org/questions/${editedQuestion.id}`, {
        newText: editedQuestion.text,
        newCategory: editedQuestion.category,
        newLanguage: editedQuestion.language
      }, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            // Add any other headers here if needed
        }
    });
      setEditedQuestion({ id: '', text: '', category: '', language: '' });
      fetchQuestions();
    } catch (error) {
      console.error('Error updating question:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (questionId) => {
    setLoading(true);
    try {
      await axios.delete(`https://api-back.greatmanagers.org/questions/${questionId}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            // Add any other headers here if needed
        }
    });
      fetchQuestions();
    } catch (error) {
      console.error('Error deleting question:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (value, key) => {
    setEditedQuestion({ ...editedQuestion, [key]: value });
  };

  const handleNewQuestionTextChange = (e) => {
    setNewQuestionText(e.target.value);
  };

  const handleNewQuestionCategoryChange = (e) => {
    setNewQuestionCategory(e.target.value);
  };

  const handleNewQuestionLanguageChange = (value) => {
    setNewQuestionLanguage(value);
  };

  const handleAddQuestion = async () => {
    setLoading(true);
    if (!newQuestionText || !newQuestionCategory || !newQuestionLanguage) {
      console.error('Question text, category, and language are required');
      setLoading(false);
      return;
    }

    try {
      await axios.post(`https://api-back.greatmanagers.org/questions`, {
        newText: newQuestionText,
        newCategory: newQuestionCategory,
        newLanguage: newQuestionLanguage
      }, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            // Add any other headers here if needed
        }
    });
      setNewQuestionText('');
      setNewQuestionCategory('');
      setNewQuestionLanguage('');
      fetchQuestions();
    } catch (error) {
      console.error('Error adding question:', error);
    } finally {
      setLoading(false);
    }
  };

  const newQuestionForm = (
    <div style={{
      marginBottom: '20px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      borderRadius: '10px',
      paddingLeft: '30px',
      paddingTop: '10px',
      paddingBottom: '30px',

    }}>
      <Row gutter={16}>
      <Col span={12}>
      <Title level={5}>Question Content:</Title>
      </Col>
      <Col span={4}>
      <Title level={5}>Question Category:</Title>
      </Col>
      <Col span={4}>
      <Title level={5}>Question Language:</Title>
      </Col>
      <Col span={4}>
      <Title level={5}>Action Controls:</Title>
      </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Input
            value={newQuestionText}
            onChange={handleNewQuestionTextChange}
            placeholder="Enter Question Text"
            style={{ 
              backgroundColor: '#F6F7FB',
              borderRadius: '10px',
              border: '1px solid #D9D9D9',
              minHeight: '32px',
              fontWeight: 'bold',
            }}
          />
        </Col>
        <Col span={4} style={
          {
            textAlign: 'right',
          }
        }>
          <Input
            value={newQuestionCategory}
            onChange={handleNewQuestionCategoryChange}
            placeholder="Enter Question Category"
            style={{ 
              backgroundColor: '#F6F7FB',
              borderRadius: '10px',
              border: '1px solid #D9D9D9',
              minHeight: '32px',
              fontWeight: 'bold',
            }}
          />
        </Col>
        <Col span={4} style={
          {
            textAlign: 'center',
          }
        }>
          <Select
            value={newQuestionLanguage}
            onChange={handleNewQuestionLanguageChange}
            placeholder="Select Question Language"
            style={{ 
              backgroundColor: '#F6F7FB',
              borderRadius: '10px',
              border: '1px solid #D9D9D9',
              minHeight: '32px',
              fontWeight: 'bold',
              width: '100%',
            }}
          >
            <Option value="English">English</Option>
            <Option value="Sinhala">Sinhala</Option>
          </Select>
        </Col>
        <Col span={2} style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" icon={<PlusOutlined />} onClick={handleAddQuestion}
             loading={loading}
             style={{
              backgroundColor:'#3B7DDE'
             }}>
              Add
            </Button>
            <Button  icon={<FormOutlined />} onClick={() => { setNewQuestionText(''); setNewQuestionCategory(''); setNewQuestionLanguage(''); }}>
              Clear
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
  

  const columns = [
    {
      title: 'Question Content',
      dataIndex: 'text',
      key: 'text',
      render: (text, record) => {
        if (record.id === editedQuestion.id) {
          return (
            <Input
              value={editedQuestion.text}
              onChange={(e) => handleInputChange(e.target.value, 'text')}
            />
          );
        }
        return text;
      }
    },
    {
      title: 'Question Category',
      dataIndex: 'category',
      key: 'category',
      render: (text, record) => {
        if (record.id === editedQuestion.id) {
          return (
            <Input
              value={editedQuestion.category}
              onChange={(e) => handleInputChange(e.target.value, 'category')}
            />
          );
        }
        return text;
      }
    },
    {
      title: 'Question Language',
      dataIndex: 'language',
      key: 'language',
      render: (text, record) => {
        if (record.id === editedQuestion.id) {
          return (
            <Select
              value={editedQuestion.language}
              onChange={(value) => handleInputChange(value, 'language')}
              placeholder="Select Question Language"
              style={{ width: 150 }}
            >
              <Option value="English">English</Option>
              <Option value="Sinhala">Sinhala</Option>
            </Select>
          );
        }
        return text;
      }
    },
    {
      title: 'Action Controls',
      key: 'actions',
      render: (text, record) => {
        if (record.id === editedQuestion.id) {
          return (
            <Space>
              <Button type="primary" onClick={handleSave} loading={loading}>Save</Button>
            </Space>
          );
        }
        return (
          <Space>
            <Button type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record)} style={{backgroundColor:'#3B7DDE'}}>
              Edit
            </Button>
            <Button type="danger" icon={<DeleteOutlined />} onClick={() => handleDelete(record.id)} loading={loading} >
              Delete
            </Button>
          </Space>
        );
      }
    }
  ];

  const handleLanguageChange = (checked) => {
    setLanguage(checked ? 'SIN' : 'ENG');
  };

  return (
    <div style={{ backgroundColor: '#F6F7FB', padding: '20px' }}>
      <Title strong>Questions Editor</Title>
      <Title level={5} style={
        {
          marginTop: '-20px',
          marginBottom: '30px',          
        }
      }>
      Create And Modify New Questions</Title>

      {newQuestionForm}
      <Space style={{ marginBottom: '10px' }}>
        <Text style={
        {
          fontSize: '16px',
        }
        }>English</Text>
        <Switch checked={language === 'SIN'} onChange={handleLanguageChange} 
          style={
            {backgroundColor: '#3B7DDE',
          }}
        />
        <Text style={
        {
          fontSize: '16px',
        }
        }>Sinhala</Text>
      </Space>
      <div style={{
      marginBottom: '20px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
      borderRadius: '10px',
      padding:'20px'

    }}>
      <Table
          dataSource={questions}
          columns={columns}
          pagination={false}
          loading={loading}
          rowKey="id"
          style={{ marginTop: '10px',borderRadius: '10px',}}
          // Add this prop to include the custom header style
          components={{
            header: {
              cell: (props) => (
                <th {...props} style={{ backgroundColor: '#F6F7FB' }} />
              ),
            },
          }}
        />
      </div>
    </div>
  );
};

export default Questions;
