import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { Select, Table, Spin, Row, Col, Button, Typography, Divider } from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import styled from 'styled-components';



const { Text } = Typography;
const { Option } = Select;
const { Title } = Typography;

const AdminDashboard = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompanyName, setSelectedCompanyName] = useState('');
    const [selectedCompanyYear, setSelectedCompanyYear] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [evaluatees, setEvaluatees] = useState([]);
    const [selectedEvaluateeIDSectionOne, setSelectedEvaluateeIDSectionOne] = useState('');
    const [selectedEvaluateeIDSectionTwo, setSelectedEvaluateeIDSectionTwo] = useState('');
    const [evaluationResults, setEvaluationResults] = useState([]);
    const [isLoadingResults, setIsLoadingResults] = useState(false);
    const [evaluators, setEvaluators] = useState([]);
    const [selectedEvaluator, setSelectedEvaluator] = useState('');
    const [responses, setResponses] = useState([]);
    const [isLoadingResponses, setIsLoadingResponses] = useState(false);

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const response = await axios.get('https://api-back.greatmanagers.org/companiesi', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setCompanies(response.data);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const fetchEvaluatees = async (companyId) => {
        try {
            const response = await axios.get(`https://api-back.greatmanagers.org/evaluatees/${companyId}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setEvaluatees(response.data);
        } catch (error) {
            console.error('Error fetching evaluatees:', error);
        }
    };

    const fetchEvaluationResults = async (evaluateeID) => {
        try {
            setIsLoadingResults(true);
            const response = await axios.get(`https://api-back.greatmanagers.org/evaluation-results/${evaluateeID}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setEvaluationResults(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            console.error('Error fetching evaluation results:', error);
        } finally {
            setIsLoadingResults(false);
        }
    };

    const fetchEvaluatorsForEvaluatee = async (evaluateeID) => {
        try {
            const response = await axios.get(`https://api-back.greatmanagers.org/evaluators/${evaluateeID}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setEvaluators(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            console.error('Error fetching evaluators:', error);
        }
    };

    const fetchResponses = async (evaluatorEmail) => {
        setIsLoadingResponses(true);
        try {
            const response = await axios.get(`https://api-back.greatmanagers.org/responses/${evaluatorEmail}/${selectedEvaluateeIDSectionTwo}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                    // Add any other headers here if needed
                }
            });
            setResponses(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
            console.error('Error fetching responses:', error);
        } finally {
            setIsLoadingResponses(false);
        }
    };

    const handleCompanyNameChange = (value) => {
        setSelectedCompanyName(value);
        setSelectedCompanyYear('');
        setCompanyId('');
        setSelectedEvaluateeIDSectionOne('');
        setSelectedEvaluateeIDSectionTwo('');
        setSelectedEvaluator('');
        setEvaluationResults([]);
        setResponses([]);
        setEvaluatees([]);
    };

    const handleCompanyYearChange = (value) => {
        setSelectedCompanyYear(value);
        const selectedCompany = companies.find(company => 
            company.CompanyName === selectedCompanyName && company.CompanyYear === parseInt(value)
        );
        if (selectedCompany) {
            setCompanyId(selectedCompany.CompanyID);
            fetchEvaluatees(selectedCompany.CompanyID);
        } else {
            setCompanyId('Not found');
        }
    };

    const handleEvaluateeSelectionSectionOne = (value) => {
        setSelectedEvaluateeIDSectionOne(value);
        fetchEvaluationResults(value);
    };

    const handleEvaluateeSelectionSectionTwo = (value) => {
        setSelectedEvaluateeIDSectionTwo(value);
        setSelectedEvaluator('');
        setResponses([]);
        fetchEvaluatorsForEvaluatee(value);
    };

    const handleEvaluatorSelection = (value) => {
        setSelectedEvaluator(value);
        fetchResponses(value);
    };

    const handleDownloadSectionOne = () => {
        const csvContent = convertTableToCSV(evaluationResults);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        // Find the evaluatee object
        const selectedEvaluatee = evaluatees.find(evaluatee => evaluatee.EvaluateeID === selectedEvaluateeIDSectionOne);
        // Construct the filename using the evaluatee's name
        const filename = selectedEvaluatee ? `${selectedEvaluatee.FirstName}_${selectedEvaluatee.LastName}_data.csv` : 'SectionOne_data.csv';
        // Use the constructed filename
        console.log(filename)
    };

    const handleDownloadSectionTwo = () => {
        const csvContent = convertTableToCSV(responses);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        // Find the evaluatee object
        const selectedEvaluatee = evaluatees.find(evaluatee => evaluatee.EvaluateeID === selectedEvaluateeIDSectionTwo);
        // Construct the filename using the evaluatee's name
        const filename = selectedEvaluatee ? `${selectedEvaluatee.FirstName}_${selectedEvaluatee.LastName}_from_${selectedEvaluator}_data.csv` : 'SectionTwo_data.csv';
        // Use the constructed filename
        saveAs(blob, filename);
    };

    const convertTableToCSV = (tableData) => {
        if (!tableData.length) return '';
        const rows = [];
        const headers = Object.keys(tableData[0]);
        rows.push(headers.join(','));
        tableData.forEach(row => {
            const values = headers.map(header => row[header]);
            rows.push(values.join(','));
        });
        return rows.join('\n');
    };

    const companyNames = [...new Set(companies.map(company => company.CompanyName))];
    const companyYears = selectedCompanyName ? 
        [...new Set(companies.filter(company => company.CompanyName === selectedCompanyName).map(company => company.CompanyYear))] : [];

    const evaluateeOptions = evaluatees.map(evaluatee => (
        <Option key={evaluatee.EvaluateeID} value={evaluatee.EvaluateeID}>
            {evaluatee.FirstName} {evaluatee.LastName}
        </Option>
    ));

    const evaluatorOptions = evaluators.map(evaluator => (
        <Option key={evaluator.EvaluatorID} value={evaluator.EvaluatorEmail}>
            {evaluator.EvaluatorEmail}
        </Option>
    ));

    const evaluationResultsColumns = [
        { title: 'Question', dataIndex: 'QuestionText', key: 'QuestionText' },
        { title: 'Average Evaluation', dataIndex: 'AverageRating', key: 'AverageRating' },
        { title: 'Response Count', dataIndex: 'ResponseCount', key: 'ResponseCount' }
    ];

    const responsesColumns = [
        { title: 'Question', dataIndex: 'QuestionText', key: 'QuestionText' },
        { title: 'Response', dataIndex: 'Response', key: 'Response' }
    ];

    return (
        <div style={{ backgroundColor: '#F6F7FB', padding: '20px' }}>
            <Title strong>Detailed Report</Title>
            <Title level={5} style={{ marginTop: '-20px', marginBottom: '30px' }}>
                Analyze Detailed Personalized Data And Insights
            </Title>

            <div style={{
                marginBottom: '20px',
                backgroundColor: '#FFFFFF',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '10px',
                padding: '10px 30px',
                paddingBottom: '30px'
            }}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Title level={5}>Company Name:</Title>
                    </Col>
                    <Col span={12}>
                        <Title level={5}>Year:</Title>
                    </Col>
                </Row>
                <Row gutter={[24]}>
                    <Col span={12}>
                        <Select
                            placeholder="Select a company name"
                            onChange={handleCompanyNameChange}
                            value={selectedCompanyName}
                            style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
                        >
                            {companyNames.map(name => (
                                <Option key={name} value={name}>{name}</Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={12}>
                        <Select
                            placeholder="Select a company year"
                            onChange={handleCompanyYearChange}
                            value={selectedCompanyYear}
                            disabled={!selectedCompanyName}
                            style={{ 
                                            backgroundColor: '#F6F7FB',
                                            borderRadius: '10px',
                                            border: '1px solid #D9D9D9',
                                            minHeight: '32px',
                                            fontWeight: 'bold',
                                            width: '100%'
                                            }}
                        >
                            {companyYears.map(year => (
                                <Option key={year} value={year}>{year}</Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            </div>

            <Row gutter={[16, 16]} className="dashboard-row">
                <Col span={12}>
                    <div style={{
                        marginBottom: '20px',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        borderRadius: '10px',
                        padding: '10px 30px',
                        paddingBottom: '30px',
                        minHeight: '704px'
                    }}>
                        <Title level={3} style={{ display: 'inline-block' }}>Average Responses</Title>
                        
                        <Button
                            type="primary"
                            style={{ marginTop: '20px', float: 'right',backgroundColor:'#3B7DDE' }}
                            onClick={handleDownloadSectionOne}
                        
                        >
                            Export as CSV
                        </Button>
                        <Title level={5}>Select a Nominee</Title>
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Select an evaluatee"
                            onChange={handleEvaluateeSelectionSectionOne}
                            value={selectedEvaluateeIDSectionOne}
                            disabled={!companyId}
                        >
                            {evaluateeOptions}
                        </Select>
                        {selectedEvaluateeIDSectionOne && (
                            <div style={{ marginTop: '20px' }}>
                                {isLoadingResults ? (
                                    <Spin />
                                ) : (
                                    <>
                                        <Table
                                            columns={evaluationResultsColumns}
                                            dataSource={evaluationResults}
                                            rowKey={(record, index) => index}
                                            pagination={{ pageSize: 5 }}
                                            components={{
            header: {
              cell: (props) => (
                <th {...props} style={{ backgroundColor: '#F6F7FB' }} />
              ),
            },
          }}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </Col>
                <Col span={12}>
                    <div style={{
                        marginBottom: '20px',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                        borderRadius: '10px',
                        padding: '10px 30px',
                        paddingBottom: '30px',
                        minHeight: '700px'
                    }}>
                        <Title level={3} style={{ display: 'inline-block' }}>Evaluator Responses</Title>
                        <Button
                            type="primary"
                            style={{ marginTop: '20px', float: 'right',backgroundColor:'#3B7DDE' }}
                            onClick={handleDownloadSectionTwo}
                        >
                            Export as CSV
                        </Button>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Title level={5}>Select a Nominee</Title>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select an evaluatee"
                                    onChange={handleEvaluateeSelectionSectionTwo}
                                    value={selectedEvaluateeIDSectionTwo}
                                    disabled={!companyId}
                                >
                                    {evaluateeOptions}
                                </Select>
                            </Col>
                            <Col span={12}>
                                {selectedEvaluateeIDSectionTwo && (
                                    <>
                                        <Title level={5}>Select an Evaluator</Title>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Select an evaluator"
                                            onChange={handleEvaluatorSelection}
                                            value={selectedEvaluator}
                                            disabled={!selectedEvaluateeIDSectionTwo}
                                        >
                                            {evaluatorOptions}
                                        </Select>
                                    </>
                                )}
                            </Col>
                        </Row>
                        {selectedEvaluator && (
                            <div style={{ marginTop: '20px' }}>
                                {isLoadingResponses ? (
                                    <Spin />
                                ) : (
                                    <>
                                        <Table
                                            columns={responsesColumns}
                                            dataSource={responses}
                                            rowKey={(record, index) => index}
                                            pagination={{ pageSize: 5 }}
                                            components={{
            header: {
              cell: (props) => (
                <th {...props} style={{ backgroundColor: '#F6F7FB' }} />
              ),
            },
          }}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default AdminDashboard;